const getCookieValue = (cookieName) => {
  const cookieString = document.cookie;

  const cookies = cookieString.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();

    // Check if this cookie starts with the name we're looking for
    if (cookie.startsWith(`${cookieName}=`)) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  // If cookie not found, return null
  return null;
};

const setLocaleCookie = (newLocale) => {
  const date = new Date();
  const expirationTime = 100 * 24 * 60 * 60 * 1000; // 100 days

  date.setTime(date.getTime() + expirationTime);
  document.cookie = `NEXT_LOCALE=${newLocale};expires=${date.toUTCString()};path=/;SameSite=None;Secure`;
};

export {
  getCookieValue,
  setLocaleCookie
};
