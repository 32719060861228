import capitalize from './capitalize-string';


const formatObjectKeys = (object) => {
  if (typeof object !== 'object' || Array.isArray(object) || object === null) return object;

  for (const key in object) {
    if (key) {
      const formattedKey = key.split('_').map((word, index) => {
        if (index) return (capitalize(word));

        return word;
      })
        .join('');

      object[formattedKey] = object[key];

      if (typeof object[formattedKey] === 'object') {
        if (!Array.isArray(object[formattedKey])) {
          object[formattedKey] = formatObjectKeys(object[formattedKey]);
        } else {
          const isObjectsArray = object[formattedKey].length &&
            object[formattedKey].filter(value => typeof value === 'object' &&
            !Array.isArray(value)).length === object[formattedKey].length;

          if (isObjectsArray) {
            const formattedArray = object[formattedKey].map(innerObject => formatObjectKeys(innerObject));

            object[formattedKey] = formattedArray;
          }
        }
      }

      if (key !== formattedKey) {
        delete object[key];
      }
    }
  }

  return object;
};

export default formatObjectKeys;
