import { configureStore } from '@reduxjs/toolkit';

// Reducers
import menusReducer from 'store/slices/menusSlice';
import productsReducer from 'store/slices/productsSlice';
import restaurantsReducer from 'store/slices/restaurantsSlice';
import userReducer from 'store/slices/userSlice';


const store = configureStore({
  reducer: {
    menus: menusReducer,
    products: productsReducer,
    restaurants: restaurantsReducer,
    user: userReducer
  }
});

export default store;
