import axios from 'axios';


const BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8080';

export default class BaseApi {
  static createConfig = headers => ({
    headers: this.createHeaders(headers),
    validateStatus: this.validateStatus
  });

  static createHeaders = (headerOptions) => {
    const { token } = JSON.parse(localStorage.getItem('user')) || {};

    const headers = {
      'Content-Type': 'application/json',
      ...headerOptions
    };

    if (token) headers.Authorization = `Bearer ${token}`;

    return headers;
  };

  static createUrl = path => `${BASE_URL}${path}`;

  // Cannot be delete, since delete is a reserved string;
  static deleteItem = (path) => {
    const url = this.createUrl(path);
    const config = this.createConfig();

    return axios.delete(url, config)
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  static get = (path, headers) => {
    const url = this.createUrl(path);
    const config = this.createConfig(headers);

    return axios.get(url, config)
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  static handleError = (errorResponse) => {
    const { response } = errorResponse || {};
    const { data, status } = response || {};
    const { error } = data || {};

    const errorData = {
      error: (error || data || response || errorResponse),
      message: (error || data || response || errorResponse)?.message,
      status
    };

    throw errorData;
  };

  static handleResponse = (response) => {
    if (!response) throw new Error('Missing response');

    const { data } = response;

    return data;
  };

  // https://restfulapi.net/http-status-codes/#4xx
  static isClientErrorResponseStatusCode = statusCode => typeof statusCode === 'number' && /^4\d\d$/.test(statusCode);

  // https://restfulapi.net/http-status-codes/#1xx
  static isInformationalResponseStatusCode = statusCode => typeof statusCode === 'number' && /^1\d\d$/.test(statusCode);

  // https://restfulapi.net/http-status-codes/#3xx
  static isRedirectionResponseStatusCode = statusCode => typeof statusCode === 'number' && /^3\d\d$/.test(statusCode);

  // https://restfulapi.net/http-status-codes/#5xx
  static isServerErrorResponseStatusCode = statusCode => typeof statusCode === 'number' && /^5\d\d$/.test(statusCode);

  // https://restfulapi.net/http-status-codes/#2xx
  static isSuccessfulResponseStatusCode = statusCode => typeof statusCode === 'number' && /^2\d\d$/.test(statusCode);

  static patch = (path, data, headers) => {
    const url = this.createUrl(path);
    const config = this.createConfig(headers);

    return axios.patch(url, data, config)
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  static post = (path, data, headers) => {
    const url = this.createUrl(path);
    const config = this.createConfig(headers);

    return axios.post(url, data, config)
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  static validateStatus = statusCode => typeof statusCode === 'number' &&
    !this.isClientErrorResponseStatusCode(statusCode) &&
    !this.isServerErrorResponseStatusCode(statusCode);
}
