import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

// Utils
import checkAuthenticationError from 'utils/check-authentication-error';
import formatObjectKeys from 'utils/format-snake-case-to-camel-case';
import MenusApi from 'utils/api/menus';

import { logoutUser } from './userSlice';

// Thunks
export const fetchMenus = createAsyncThunk(
  'menus/fetchMenus',
  (_, { rejectWithValue }) => MenusApi.list()
    .then((data) => {
      let filteredMenus = [];

      if (Array.isArray(data.data)) {
        const formattedMenus = data.data.map(menu => formatObjectKeys(menu));

        filteredMenus = formattedMenus.map(menu => ({
          entityId: menu.id,
          menuSections: menu.menuSections.map(section => ({
            entityId: section.id,
            order: section.order,
            products: section.menuSectionProducts.map(sectionProduct => ({
              entityId: sectionProduct.product.id,
              sectionProductId: sectionProduct.id
            })),
            title: section.name
          }))
        }));
      }

      return filteredMenus;
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const updateMenu = createAsyncThunk(
  'menus/updateMenu',
  ({ menuId, updatedMenuData, sortedSections }, { rejectWithValue }) => {
    const {
      deletedMenuSections,
      deletedProductsBySectionId,
      menuSections
    } = updatedMenuData || {};

    const menuSectionsAttributes = [];

    if (Array.isArray(menuSections)) {
      /* eslint-disable camelcase */
      menuSections.forEach((section) => {
        const menuSectionProductsAttributes = [];

        if (Array.isArray(section?.products)) {
          section.products.forEach((product, index) => {
            // Add section's products attributes to menuSectionProductsAttributes
            menuSectionProductsAttributes.push({
              id: product.sectionProductId || null,
              order: index,
              product_id: product.entityId
            });
          });
        }

        if (Array.isArray(deletedProductsBySectionId[section.entityId]) && deletedProductsBySectionId[section.entityId].length) {
          deletedProductsBySectionId[section.entityId].forEach((sectionProductToDestroyId) => {
            const sectionProductToDestroy = {
              _destroy: 1,
              id: sectionProductToDestroyId
            };

            menuSectionProductsAttributes.push(sectionProductToDestroy);
          });
        }

        const sectionIndex = sortedSections.findIndex(initialFormId => initialFormId === section.initialFormId);

        const sectionAttributesObject = {
          id: section.entityId || null,
          menu_section_products_attributes: menuSectionProductsAttributes,
          name: section.title,
          order: sectionIndex + 1 || section.order
        };

        menuSectionsAttributes.push(sectionAttributesObject);
      });
    }

    if (deletedMenuSections?.length) {
      deletedMenuSections.forEach((sectionToDestroyId) => {
        const sectionToDestroy = {
          _destroy: 1,
          id: sectionToDestroyId
        };

        menuSectionsAttributes.push(sectionToDestroy);
      });
    }

    const formattedNewMenuData = {
      menu: {
        menu_sections_attributes: menuSectionsAttributes
      }
    };
    /* eslint-enable camelcase */

    return MenusApi.update(menuId, formattedNewMenuData)
      .then((data) => {
        const formattedMenuData = formatObjectKeys(data?.data);
        let filteredMenuData = {};

        if (Array.isArray(formattedMenuData?.menuSections)) {
          filteredMenuData = {
            entityId: formattedMenuData.id,
            menuSections: formattedMenuData.menuSections.map(section => ({
              entityId: section.id,
              order: section.order,
              products: Array.isArray(section.menuSectionProducts) ?
                section.menuSectionProducts.map(sectionProduct => ({
                  entityId: sectionProduct.product.id,
                  sectionProductId: sectionProduct.id
                })) :
                [],
              title: section.name
            }))
          };
        }

        return filteredMenuData;
      })
      .catch((error) => {
        checkAuthenticationError(error);

        return rejectWithValue(error);
      });
  }
);

// Entity Adapter
const menusAdapter = createEntityAdapter({
  selectId: menu => menu.entityId
});

// Additional Initial State Options
const additionalInitialStateOptions = {
  isFetchingMenus: false,
  isUpdatingMenus: false
};

const menusSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Fetch Menus Handlers
      .addCase(fetchMenus.pending, (state) => {
        state.isFetchingMenus = true;
      })
      .addCase(fetchMenus.fulfilled, (state, { payload }) => {
        state.isFetchingMenus = false;

        const menus = payload;

        if (menus) {
          menusAdapter.setAll(state, menus);
        }
      })
      .addCase(fetchMenus.rejected, (state) => {
        state.isFetchingMenus = false;
      })
      // Handle Logout
      .addCase(logoutUser, () => ({
        ...menusAdapter.getInitialState()
      }))
      // Update Menu Handlers
      .addCase(updateMenu.pending, (state) => {
        state.isUpdatingMenus = true;
      })
      .addCase(updateMenu.fulfilled, (state, { payload }) => {
        state.isUpdatingMenus = false;

        const menu = payload;

        if (menu) {
          menusAdapter.upsertOne(state, menu);
        }
      })
      .addCase(updateMenu.rejected, (state) => {
        state.isUpdatingMenus = false;
      });
  },

  initialState: menusAdapter.getInitialState(additionalInitialStateOptions),

  name: 'menus'
});

export default menusSlice.reducer;

// Selectors
const menusSelectors = menusAdapter.getSelectors(state => state.menus);

export const { selectAll: selectMenus } = menusSelectors;

export const selectCurrentMenu = createSelector(
  selectMenus,
  menus => menus[0]
);

export const selectIsFetchingMenus = state => state.menus.isFetchingMenus;

export const selectIsUpdatingMenus = state => state.menus.isUpdatingMenus;
