import { emptyNewSchedule, weekdaysByNumber } from './schedule-helper-variables';


const formatScheduleData = (scheduleDayBlocks) => {
  if (!Array.isArray(scheduleDayBlocks)) return emptyNewSchedule;

  return scheduleDayBlocks.reduce((newSchedule, dayBlock) => {
    const weekday = weekdaysByNumber[dayBlock.weekday];

    const timeEntry = {
      closeTime: dayBlock.endHour,
      entityId: dayBlock.id,
      openTime: dayBlock.startHour
    };

    const weekdayPositionInNewSchedule = newSchedule.findIndex(entry => entry.day === weekday);

    if (weekdayPositionInNewSchedule !== -1) {
      newSchedule[weekdayPositionInNewSchedule].timeEntries.push(timeEntry);

      if (!newSchedule[weekdayPositionInNewSchedule].active) newSchedule[weekdayPositionInNewSchedule].active = true;
    } else {
      const newDay = {
        active: true,
        day: weekday,
        timeEntries: [timeEntry]
      };

      newSchedule.push(newDay);
    }

    return newSchedule;
  }, JSON.parse(JSON.stringify(emptyNewSchedule)));
};

export default formatScheduleData;
