import { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';


const DropdownContext = createContext();

const DropdownContextProvider = ({ children }) => {
  const dropdownRefs = useRef([]);

  const addDropdownRef = (ref) => {
    dropdownRefs.current.push(ref);
  };

  const onDropdownOpenStateChange = (targetDropdownRef, openState) => {
    // Auto-close dropdowns when another one is opened
    if (targetDropdownRef?.current) {
      // Improvement – We should try to:
      // * identify refs somehow to prevent closing of the target dropdown (and opening it again as a workaround)
      // * get the current open state of each ref and call close only on those who are open
      //   * this would also allow the usage of refs instead of querySelectorAll for the scrollbar fix below

      // Close all dropdowns
      for (const dropdownRef of dropdownRefs.current) if (dropdownRef?.current) dropdownRef.current.close();

      // Re-open target dropdown if it was open
      if (openState) targetDropdownRef.current.open();
    }

    // Prevent Radix’s removal of scrollbars
    const elements = document.querySelectorAll('.context-radix-dropdown-menu-trigger');
    const elementsArray = Array.from(elements);

    if (elementsArray && elementsArray.length) {
      const thereIsADropdownOpen = elementsArray.some(entry => entry?.dataset?.state === 'open');

      if (thereIsADropdownOpen) {
        // This logic will be uncommented when pages with "sticky" header and footer will have those components "fixed" instead
        // document.documentElement.style.overflow = 'initial';

        // This is necessary to override Radix injected styles in body
        // This logic will be removed when pages with "sticky" header and footer will have those components "fixed" instead
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('margin-right');
      } else {
        // This logic will be uncommented when pages with "sticky" header and footer will have those components "fixed" instead
        // document.documentElement.style.overflow = 'auto';

        // This is necessary to override Radix injected styles in body
        // This logic will be removed when pages with "sticky" header and footer will have those components "fixed" instead
        document.body.style.setProperty('overflow', 'auto', 'important');
        document.body.style.setProperty('margin-right', 0, 'important');
      }
    }
  };

  const value = {
    addDropdownRef,
    onDropdownOpenStateChange
  };

  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  );
};

DropdownContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

DropdownContextProvider.defaultProps = {
  children: null
};

const useDropdownContext = () => useContext(DropdownContext);

export {
  DropdownContextProvider,
  useDropdownContext
};
