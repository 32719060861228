import BaseApi from 'utils/api/base-api';

import {
  PRODUCTS
} from 'utils/api/endpoints';


export default class ProductsApi extends BaseApi {
  static create = (data, headers) => this.post(PRODUCTS, data, headers);

  static remove = productId => this.deleteItem(`${PRODUCTS}/${productId}`);

  static list = () => this.get(PRODUCTS);

  static update = (productId, data, headers) => this.patch(`${PRODUCTS}/${productId}`, data, headers);
}
