import React from 'react';
import NextHead from 'next/head';
import PropTypes from 'prop-types';

// Data
const twitterCreatorContent = process.env.NEXT_PUBLIC_TWITTER_CREATOR_CONTENT;
const twitterSiteContent = process.env.NEXT_PUBLIC_TWITTER_SITE_CONTENT;
const websiteDescription = process.env.NEXT_PUBLIC_WEBSITE_DESCRIPTION;
const websiteTitle = process.env.NEXT_PUBLIC_WEBSITE_TITLE;
const websiteURL = process.env.NEXT_PUBLIC_WEBSITE_URL;

const Head = ({
  description,
  isCdnLink,
  pagePath,
  shareImage,
  title
}) => (
  <NextHead>
    <meta
      key="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
      name="viewport"
    />
    <title>
      {title}
    </title>
    <meta
      key="description"
      content={description}
      name="description"
    />
    <meta
      key="og-url"
      content={`${websiteURL}/${pagePath}`}
      property="og:url"
    />
    <meta
      key="og-title"
      content={title}
      property="og:title"
    />
    <meta
      key="og-site_name"
      content={title}
      property="og:site_name"
    />
    <meta
      key="og-description"
      content={description}
      property="og:description"
    />
    <meta
      key="og-type"
      content="website"
      property="og:type"
    />
    <meta
      key="og-image"
      content={isCdnLink ? `${shareImage}` : `${websiteURL}/open-graph/${shareImage}`}
      property="og:image"
    />
    <meta
      key="twitter-card"
      content="summary_large_image"
      name="twitter:card"
    />
    <meta
      key="twitter-image"
      content={isCdnLink ? `${shareImage}` : `${websiteURL}/twitter-card/${shareImage}`}
      name="twitter:image"
    />
    <meta
      key="twitter-site"
      content={twitterSiteContent}
      name="twitter:site"
    />
    <meta
      key="twitter-creator"
      content={twitterCreatorContent}
      name="twitter:creator"
    />
    <meta
      key="twitter-title"
      content={title}
      name="twitter:title"
    />
    <meta
      key="twitter-description"
      content={description}
      name="twitter:description"
    />
    <link
      href={`${websiteURL}/sitemap.xml`}
      rel="sitemap"
      type="application/xml"
    />
  </NextHead>
);

Head.propTypes = {
  description: PropTypes.string,
  isCdnLink: PropTypes.bool,
  pagePath: PropTypes.string,
  shareImage: PropTypes.string,
  title: PropTypes.string
};

Head.defaultProps = {
  description: websiteDescription,
  isCdnLink: false,
  pagePath: '',
  shareImage: 'share.jpg',
  title: websiteTitle
};

export default Head;
