// Convert style module keys in BEM notation to valid JavaScript to avoid escaping keys with bracket notation.

const bemify = (module) => {
  const bemifiedModule = {};

  Object.entries(module).forEach(([key, value]) => {
    const bemifiedKey = key
      // use camelCase: kebab-case to camelCase
      // Lookbehind assertion does not have good browser compatibility yet, we need to remove double hyphens firstly
      // .replace(/(?<!-)-([a-zA-Z])/g, capture => capture.substring(1).toUpperCase())
      // use snake_kase for modifiers: `--` to `_`
      .replace(/--/g, '_')
      // use camelCase: kebab-case to camelCase
      .replace(/-([a-zA-Z])/g, capture => capture.substring(1).toUpperCase());

    bemifiedModule[bemifiedKey] = value;
  });

  return bemifiedModule;
};

export default bemify;
