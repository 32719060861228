import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

// Utils
import checkAuthenticationError from 'utils/check-authentication-error';
import formatObjectKeys from 'utils/format-snake-case-to-camel-case';
import UsersApi from 'utils/api/users';


// Initial State
const initialState = {
  authenticationInitialized: false,
  displaySessionExpiredMessage: false,
  email: null,
  familyName: null,
  givenName: null,
  hasRestaurant: false,
  isFetchingUser: false,
  isLoggingIn: false,
  isRegistering: false,
  isRequestingPasswordResetToken: false,
  isRequestingUpdate: false,
  isResettingPassword: false,
  photo: null,
  token: null
};

// Thunks
export const login = createAsyncThunk(
  'user/login',
  (data, { rejectWithValue }) => {
    const {
      authorizationHeader,
      email,
      password,
      googleAccessToken
    } = data || {};

    let requestPayload = null;

    if ((email && password) || googleAccessToken) {
      requestPayload = {
        /* eslint-disable camelcase */
        email,
        google_access_token: googleAccessToken,
        password
        /* eslint-enable camelcase */
      };
    }

    return UsersApi.login(requestPayload, authorizationHeader)
      .then(responseData => formatObjectKeys(responseData))
      .catch(error => rejectWithValue(error));
  }
);

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  (_, { rejectWithValue }) => UsersApi.fetchUser()
    .then(data => formatObjectKeys(data))
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const passwordReset = createAsyncThunk(
  'user/passwordReset',
  ({ password, token }, { rejectWithValue }) => {
    const requestData = {
      password,
      token
    };

    return UsersApi.resetPassword(requestData)
      .catch((error) => {
        checkAuthenticationError(error);

        return rejectWithValue(error);
      });
  }
);

export const passwordResetToken = createAsyncThunk(
  'user/passwordResetToken',
  ({ email }, { rejectWithValue }) => UsersApi.requestPasswordReset({ email })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const register = createAsyncThunk(
  'user/register',
  ({ email, familyName, givenName, password, accessToken }, { rejectWithValue }) => UsersApi.register({
    user: {
      email,
      /* eslint-disable camelcase */
      family_name: familyName,
      given_name: givenName,
      google_access_token: accessToken,
      /* eslint-enable camelcase */
      password
    }
  })
    .then(data => formatObjectKeys(data))
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const resendPasswordResetToken = createAsyncThunk(
  'user/resendPasswordResetToken',
  ({ email }, { rejectWithValue }) => UsersApi.requestPasswordResetResend({ email })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const update = createAsyncThunk(
  'user/update',
  (data, { rejectWithValue }) => UsersApi.update(data)
    .then(responseData => formatObjectKeys(responseData))
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  ({ currentPassword, newPassword }, { rejectWithValue }) => UsersApi.updatePassword({
    user: {
      /* eslint-disable camelcase */
      current_password: currentPassword,
      new_password: newPassword
      /* eslint-enable camelcase */
    }
  })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

// Slice
const userSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Login Handlers
      .addCase(login.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoggingIn = false;

        if (payload) userSlice.caseReducers.setUserData(state, { payload });
      })
      .addCase(login.rejected, (state) => {
        state.isLoggingIn = false;
      })
      // Fetch User Handlers
      .addCase(fetchUser.pending, (state) => {
        state.isFetchingUser = true;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.isFetchingUser = false;


        if (payload) userSlice.caseReducers.setUserData(state, { payload });
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isFetchingUser = false;
      })
      // Password Reset Handlers
      .addCase(passwordReset.pending, (state) => {
        state.isResettingPassword = true;
      })
      .addCase(passwordReset.fulfilled, (state) => {
        state.isResettingPassword = false;
      })
      .addCase(passwordReset.rejected, (state) => {
        state.isResettingPassword = false;
      })
      // Request Password Reset Token Handlers
      .addCase(passwordResetToken.pending, (state) => {
        state.isRequestingPasswordResetToken = true;
      })
      .addCase(passwordResetToken.fulfilled, (state) => {
        state.isRequestingPasswordResetToken = false;
      })
      .addCase(passwordResetToken.rejected, (state) => {
        state.isRequestingPasswordResetToken = false;
      })
      // Sign Up Handlers
      .addCase(register.pending, (state) => {
        state.isRegistering = true;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.isRegistering = false;

        if (payload) userSlice.caseReducers.setUserData(state, { payload });
      })
      .addCase(register.rejected, (state) => {
        state.isRegistering = false;
      })
      // Resend Password Reset Token Handlers
      .addCase(resendPasswordResetToken.pending, (state) => {
        state.isRequestingPasswordResetToken = true;
      })
      .addCase(resendPasswordResetToken.fulfilled, (state) => {
        state.isRequestingPasswordResetToken = false;
      })
      .addCase(resendPasswordResetToken.rejected, (state) => {
        state.isRequestingPasswordResetToken = false;
      })
      // Update General Info Handlers
      .addCase(update.pending, (state) => {
        state.isRequestingUpdate = true;
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        state.isRequestingUpdate = false;

        if (payload) userSlice.caseReducers.setUserData(state, { payload });
      })
      .addCase(update.rejected, (state) => {
        state.isRequestingUpdate = false;
      })
      // Update Password Handlers
      .addCase(updatePassword.pending, (state) => {
        state.isRequestingUpdate = true;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.isRequestingUpdate = false;
      })
      .addCase(updatePassword.rejected, (state) => {
        state.isRequestingUpdate = false;
      });
  },

  initialState,

  name: 'user',

  reducers: {
    disableDisplaySessionExpiredMessage: (state) => {
      state.displaySessionExpiredMessage = false;
    },
    initialize: (state) => {
      const storedUser = JSON.parse(localStorage.getItem('user'));

      if (storedUser) {
        userSlice.caseReducers.setUserData(state, { payload: storedUser });
      } else {
        localStorage.removeItem('user');
      }

      state.authenticationInitialized = true;
    },
    logoutUser: (state, { payload }) => {
      localStorage.removeItem('user');

      const { displaySessionExpiredMessage } = payload || {};

      return {
        ...initialState,
        authenticationInitialized: state.authenticationInitialized,
        displaySessionExpiredMessage
      };
    },
    setUserData: (state, { payload }) => {
      const {
        email,
        familyName,
        givenName,
        hasRestaurant,
        isOauth,
        locale,
        photo,
        token
      } = payload || {};


      if (email && token) {
        state.email = email;
        state.token = token;
        state.hasRestaurant = hasRestaurant;
        state.isOauth = isOauth;
        state.locale = locale;
        state.familyName = familyName;
        state.givenName = givenName;
        state.photo = photo;

        localStorage.setItem('user', JSON.stringify(payload));
      }
    }
  }
});

export default userSlice.reducer;

// Actions
export const {
  disableDisplaySessionExpiredMessage,
  initialize,
  logoutUser
} = userSlice.actions;

// Selectors
export const selectAuthenticationInitialized = state => state.user.authenticationInitialized;

export const selectEmail = state => state.user.email;

export const selectDisplaySessionExpiredMessage = state => state.user.displaySessionExpiredMessage;

export const selectHasRestaurant = state => state.user.hasRestaurant;

export const selectIsFetchingUser = state => state.user.isFetchingUser;

export const selectIsLoggingIn = state => state.user.isLoggingIn;

export const selectIsOauth = state => state.user.isOauth;

export const selectIsRegistering = state => state.user.isRegistering;

export const selectIsRequestingPasswordResetToken = state => state.user.isRequestingPasswordResetToken;

export const selectIsRequestingUpdate = state => state.user.isRequestingUpdate;

export const selectIsResettingPassword = state => state.user.isResettingPassword;

export const selectLocale = state => state.user.locale;

export const selectFamilyName = state => state.user.familyName;

export const selectGivenName = state => state.user.givenName;

export const selectPhoto = state => state.user.photo;

export const selectToken = state => state.user.token;

export const selectUser = state => state.user;

export const selectUserIsAuthenticated = state => !!state.user.token;
