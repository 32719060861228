import React from 'react';
import PropTypes from 'prop-types';

import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Loader = ({ className, color, direction, icon }) => (
  <Icon
    className={`${styles.loader}${
      className ? ` ${className}` : ''}${
      color === 'white' ? ` ${styles.loader_white}` : ''}${
      direction === 'anti-clockwise' ? ` ${styles.loader_animateAntiClockwise}` : ''}${
      icon === 'image-loader' ? ` ${styles.loader_media}` : ''}`}
    name={icon}
  />
);

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['orange', 'white']),
  direction: PropTypes.oneOf(['anti-clockwise', 'clockwise']),
  icon: PropTypes.string
};

Loader.defaultProps = {
  className: null,
  color: null,
  direction: 'clockwise',
  icon: 'loader'
};

export default Loader;
