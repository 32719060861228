import BaseApi from 'utils/api/base-api';

import {
  MENUS
} from 'utils/api/endpoints';


export default class MenusApi extends BaseApi {
  static list = () => this.get(MENUS);

  static update = (menuId, data) => this.patch(`${MENUS}/${menuId}`, data);
}
