const formatHolidaysData = (scheduleClosedEvents) => {
  const holidays = [];

  if (Array.isArray(scheduleClosedEvents) && scheduleClosedEvents.length) {
    scheduleClosedEvents.forEach((event) => {
      const {
        endDay,
        startDay
      } = event.startAndEndDays || {};

      if (endDay && startDay) {
        holidays.push({
          active: true,
          dates: [
            {
              from: startDay
                .split('-')
                .reverse()
                .join('-'),
              to: endDay
                .split('-')
                .reverse()
                .join('-')
            }
          ],
          entityId: event.id
        });
      }
    });
  }

  return holidays;
};

export default formatHolidaysData;
