import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

// Utils
import checkAuthenticationError from 'utils/check-authentication-error';
import formatObjectKeys from 'utils/format-snake-case-to-camel-case';
import ProductsApi from 'utils/api/products';

import { logoutUser } from './userSlice';

// Adapter
const productsAdapter = createEntityAdapter({
  selectId: product => product.entityId,
  // Place Last Updated Products on top;
  sortComparer: (currentProduct, nextProduct) => {
    const currentProductDate = new Date(currentProduct.updatedAt);
    const nextProductDate = new Date(nextProduct.updatedAt);

    return nextProductDate.getTime() - currentProductDate.getTime();
  }
});

// Additional Initial State Options
const additionalInitialStateOptions = {
  isFetchingProducts: false,
  isUpdatingProducts: false
};

// Thunks
export const createProduct = createAsyncThunk(
  'products/createProduct',
  (productData, { rejectWithValue }) => ProductsApi.create(productData, { 'Content-Type': 'multipart/form-data' })
    .then((data) => {
      const {
        data: product,
        imageUploadErrors
      } = data || {};


      let formattedProduct = formatObjectKeys(product);

      if (formattedProduct) {
        const {
          name: productName,
          id,
          ...productPropertiesToKeep
        } = formattedProduct;

        formattedProduct = {
          ...productPropertiesToKeep,
          entityId: id,
          title: productName
        };
      }

      return {
        imageUploadErrors,
        product: formattedProduct
      };
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  ({ productId }, { rejectWithValue }) => ProductsApi.remove(productId)
    .then(() => productId)
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  (_, { rejectWithValue }) => ProductsApi.list()
    .then((data) => {
      let formattedProducts = [];

      if (Array.isArray(data.data)) {
        formattedProducts = data.data.map(product => formatObjectKeys(product));

        formattedProducts = formattedProducts.map(({
          name,
          id,
          ...productPropertiesToKeep
        }) => ({
          ...productPropertiesToKeep,
          entityId: id,
          title: name
        }));
      }

      return formattedProducts;
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  ({ productId, updatedProductData }, { rejectWithValue }) => ProductsApi.update(productId, updatedProductData, { 'Content-Type': 'multipart/form-data' })
    .then((data) => {
      const {
        data: product,
        imageUploadErrors
      } = data || {};

      let formattedProduct = formatObjectKeys(product);

      if (formattedProduct) {
        const {
          name: productName,
          id,
          ...productPropertiesToKeep
        } = formattedProduct;

        formattedProduct = {
          ...productPropertiesToKeep,
          entityId: id,
          title: productName
        };

        if (formattedProduct.name) delete formattedProduct.name;
      }

      return {
        imageUploadErrors,
        product: formattedProduct
      };
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

// Slice
const productsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Fetch Products Handlers
      .addCase(fetchProducts.pending, (state) => {
        state.isFetchingProducts = true;
      })
      .addCase(fetchProducts.fulfilled, (state, { payload }) => {
        const products = payload;

        if (Array.isArray(products)) {
          productsAdapter.setAll(state, products);
        }

        state.isFetchingProducts = false;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.isFetchingProducts = false;
      })
      // Create Product Handlers
      .addCase(createProduct.pending, (state) => {
        state.isUpdatingProducts = true;
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.isUpdatingProducts = false;

        const { product } = payload || {};

        if (product) {
          productsAdapter.upsertOne(state, product);
        }
      })
      .addCase(createProduct.rejected, (state) => {
        state.isUpdatingProducts = false;
      })
      // Delete Product Handlers
      .addCase(deleteProduct.pending, (state) => {
        state.isUpdatingProducts = true;
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        state.isUpdatingProducts = false;

        const productId = payload;

        if (productId) {
          productsAdapter.removeOne(state, productId);
        }
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.isUpdatingProducts = false;
      })
      // Handle Logout
      .addCase(logoutUser, () => ({
        ...productsAdapter.getInitialState()
      }))
      // Update Product Handlers
      .addCase(updateProduct.pending, (state) => {
        state.isUpdatingProducts = true;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.isUpdatingProducts = false;

        const { product } = payload || {};

        if (product) {
          productsAdapter.upsertOne(state, product);
        }
      })
      .addCase(updateProduct.rejected, (state) => {
        state.isUpdatingProducts = false;
      });
  },

  initialState: productsAdapter.getInitialState(additionalInitialStateOptions),

  name: 'products'
});

export default productsSlice.reducer;

// Selectors
const productsSelectors = productsAdapter.getSelectors(state => state.products);

export const {
  selectAll: selectProducts,
  selectById
} = productsSelectors;

export const selectBeverages = createSelector(
  selectProducts,
  products => products.filter(product => product.isBeverage)
);

export const selectDishes = createSelector(
  selectProducts,
  products => products.filter(product => !product.isBeverage)
);

export const selectProductById = id => state => selectById(state, id);

export const selectIsFetchingProducts = state => state.products.isFetchingProducts;

export const selectIsUpdatingProducts = state => state.products.isUpdatingProducts;
