import BaseApi from 'utils/api/base-api';

import {
  GET_USER,
  LOGIN,
  REGISTER,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_RESEND,
  RESET_PASSWORD,
  UPDATE_USER_GENERAL,
  UPDATE_USER_PASSWORD
} from 'utils/api/endpoints';


export default class UsersApi extends BaseApi {
  /* eslint-disable camelcase */
  static fetchUser = () => this.get(GET_USER);

  static login = (data, headers) => {
    if (!data && headers) return this.get(GET_USER, headers);

    return this.post(LOGIN, data);
  };

  static register = data => this.post(REGISTER, data);

  static requestPasswordReset = data => this.post(REQUEST_PASSWORD_RESET, data);

  static requestPasswordResetResend = data => this.post(REQUEST_PASSWORD_RESET_RESEND, data);

  static resetPassword = data => this.post(RESET_PASSWORD, data);

  static update = data => this.patch(UPDATE_USER_GENERAL, data);

  static updatePassword = data => this.patch(UPDATE_USER_PASSWORD, data);
  /* eslint-enable camelcase */
}
