
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18nProvider from 'next-translate/I18nProvider';
import setLanguage from 'next-translate/setLanguage';
import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { Inter } from 'next/font/google';

import 'styles/globals.scss';
import 'focus-visible';

// Context
import { DropdownContextProvider } from 'context/dropdown-context';
import { ToastContextProvider } from 'context/toast-context';

// Store
import store from 'store/store';
import { Provider } from 'react-redux';

// Hooks
import useRoutes from 'hooks/use-routes';

// Utils
import getBrowserHeight from 'utils/get-browser-height';
import { getCookieValue } from 'utils/cookies-helper';

// Components
import Layout from 'components/layout';
import NavigationGuard from 'components/navigation-guard';

// Import default locale files to use as fallback
import blogEN from '../data/locales/en-EN/blog';
import commonEN from '../data/locales/en-EN/common';
import resetPasswordEN from '../data/locales/en-EN/reset-password';
import loginEN from '../data/locales/en-EN/login';
import notFoundPageEN from '../data/locales/en-EN/404';
import requestPasswordResetEN from '../data/locales/en-EN/request-password-reset';
import registerEN from '../data/locales/en-EN/register';
import restaurantMenuEN from '../data/locales/en-EN/restaurant-menu';
import styleguideEN from '../data/locales/en-EN/styleguide';


const inter = Inter({
  subsets: ['latin']
});

const MyApp = ({ Component, pageProps }) => {
  const { lang } = useTranslationWithFallback();

  const router = useRouter();

  const routes = useRoutes();

  const { pathname } = router;
  const layoutProps = routes[pathname];

  const handleWindowResize = useDebouncedCallback(() => {
    getBrowserHeight();
  }, 300);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      getBrowserHeight();

      window.addEventListener('resize', handleWindowResize);
    }

    const locale = getCookieValue('NEXT_LOCALE');

    if (locale && locale !== lang) {
      const updateLocate = async () => {
        await setLanguage(locale);
      };

      updateLocate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is necessary in order to set the --font-inter variable
  /* eslint-disable react/jsx-max-props-per-line */
  /* eslint-disable react/no-unknown-property */
  return (
    <>
      <style
        global
        jsx
      >
        {`
          :root {
            --font-inter: ${inter.style.fontFamily};
          }
        `}
      </style>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <I18nProvider
          lang={lang}
          namespaces={{
            blogEN,
            commonEN,
            loginEN,
            'not-found-pageEN': notFoundPageEN,
            registerEN,
            'request-password-resetEN': requestPasswordResetEN,
            'reset-passwordEN': resetPasswordEN,
            'restaurant-menu': restaurantMenuEN,
            styleguideEN
          }}
        >
          <DropdownContextProvider>
            <ToastContextProvider>
              <Provider store={store}>
                <Layout
                  className={inter.className}
                  {...layoutProps}
                >
                  <NavigationGuard>
                    <Component {...pageProps} />
                  </NavigationGuard>
                </Layout>
              </Provider>
            </ToastContextProvider>
          </DropdownContextProvider>
        </I18nProvider>
      </GoogleOAuthProvider>
    </>
  );
  /* eslint-disable react/jsx-max-props-per-line */
  /* eslint-disable react/no-unknown-property */
};

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape().isRequired
};

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  