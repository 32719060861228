import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Context
import { useToastContext } from 'context/toast-context';

// Store
import { useDispatch } from 'react-redux';
import { initialize as initializeAuthentication } from 'store/slices/userSlice';

// Utils
import bemify from 'utils/bemify';

// Components
import Head from 'components/head';
import Toast from 'components/toast';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Layout = ({
  children,
  className,
  description,
  shareImage,
  title
}) => {
  const dispatch = useDispatch();

  const {
    description: toastDescription,
    icon: toastIcon,
    isOpen: isToastOpen,
    showToast
  } = useToastContext();

  let mainClassName = [styles.layout__main];

  if (className) mainClassName.push(className);

  mainClassName = mainClassName.join(' ');

  useEffect(() => {
    dispatch(initializeAuthentication());
  }, [dispatch]);

  return (
    <div className={styles.layout}>
      <Head
        description={description}
        shareImage={shareImage}
        title={title}
      />
      <main className={styles.mainClassName}>
        {children}
        <Toast
          description={toastDescription}
          icon={toastIcon}
          isOpen={isToastOpen}
          showToast={showToast}
        />
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  description: PropTypes.string,
  shareImage: PropTypes.string,
  title: PropTypes.string
};


/* eslint-disable no-undefined */
Layout.defaultProps = {
  children: null,
  className: null,
  description: undefined,
  shareImage: undefined,
  title: undefined
};
/* eslint-enable no-undefined */

export default Layout;
