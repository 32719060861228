import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';


const ToastContext = createContext();

const ToastContextProvider = ({ children }) => {
  const [description, setDescription] = useState(null);
  const [icon, setIcon] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToastVisibilityChange = (value) => {
    setIsOpen(value);
  };

  const showToast = (text, state) => {
    let iconToShow = 'info-circle';

    if (state === 'error') iconToShow = 'warning';
    else if (state === 'success') iconToShow = 'check-circle--green';

    setDescription(text);
    setIcon(iconToShow);
    setIsOpen(true);
  };

  const value = {
    description,
    handleToastVisibilityChange,
    icon,
    isOpen,
    showToast
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
    </ToastContext.Provider>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

ToastContextProvider.defaultProps = {
  children: null
};

const useToastContext = () => useContext(ToastContext);

export {
  ToastContextProvider,
  useToastContext
};
