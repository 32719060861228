import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import setLanguage from 'next-translate/setLanguage';

// Hooks
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Utils
import { setLocaleCookie } from 'utils/cookies-helper';

// Store
import { useSelector } from 'react-redux';
import {
  selectAuthenticationInitialized,
  selectHasRestaurant,
  selectLocale,
  selectToken
} from 'store/slices/userSlice';

// Components
import Loader from 'components/loader';


const NavigationGuard = ({ children }) => {
  const { lang } = useTranslationWithFallback();

  const router = useRouter();
  const {
    isReady,
    pathname: pathName,
    query
  } = router;

  // Selectors
  const authenticationInitialized = useSelector(selectAuthenticationInitialized);
  const hasRestaurant = useSelector(selectHasRestaurant);
  const locale = useSelector(selectLocale);
  const token = useSelector(selectToken);

  // Routes
  const isAuthenticationRoute = [
    '/login',
    '/register',
    '/reset-password',
    '/request-password-reset'
  ].includes(pathName);

  const isPublicRoute = [
    '/404',
    '/restaurant/[slug]'
  ].includes(pathName);

  const isWelcomeRoute = pathName === '/welcome';

  const preventRedirect = useMemo(() => (pathName === '/login' && Object.keys(query).includes('token')) || !isReady, [isReady, pathName, query]);

  const loaderElement = (
    <div className="full-page-loader-wrapper">
      <Loader />
    </div>
  );

  useEffect(() => {
    if (locale && lang && locale !== lang) {
      const updateLocate = async () => {
        await setLanguage(locale);
      };

      updateLocate();
      setLocaleCookie(locale);
    }
  }, [lang, locale]);

  if (isPublicRoute) return children;

  if (!authenticationInitialized) return loaderElement;

  if (token && !preventRedirect) {
    if (isAuthenticationRoute) {
      router.push('/');

      return loaderElement;
    }

    if (hasRestaurant && isWelcomeRoute) {
      router.push('/');

      return loaderElement;
    }

    if (!hasRestaurant && !isWelcomeRoute) {
      router.push('/welcome');

      return loaderElement;
    }
  } else if (!isAuthenticationRoute && !preventRedirect) {
    router.push('/login');

    return loaderElement;
  }

  return children;
};

NavigationGuard.defaultProps = {
  children: null
};

NavigationGuard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default NavigationGuard;
