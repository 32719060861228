import useTranslationWithFallback from './use-translation-with-fallback';


// TODO: Add missing descriptions
const useRoutes = () => {
  const { t } = useTranslationWithFallback('common');

  const routes = {
    '/': {
      title: t('routes_metadata.dashboard.title')
    },
    '/404': {
      description: t('routes_metadata.404.description'),
      title: t('routes_metadata.404.title')
    },
    '/account-settings': {
      title: t('routes_metadata.account_settings.title')
    },
    '/edit-menu': {
      title: t('routes_metadata.edit_menu.title')
    },
    '/login': {
      title: t('routes_metadata.login.title')
    },
    '/products': {
      title: t('routes_metadata.products.title')
    },
    '/register': {
      title: t('routes_metadata.register.title')
    },
    '/request-password-reset': {
      title: t('routes_metadata.request_password_reset.title')
    },
    '/reset-password': {
      title: t('routes_metadata.reset_password.title')
    },
    '/restaurant-profile': {
      title: t('routes_metadata.restaurant_profile.title')
    },
    '/welcome': {
      title: t('routes_metadata.welcome.title')
    }
  };

  return routes;
};

export default useRoutes;
