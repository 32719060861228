import React from 'react';
import PropTypes from 'prop-types';

// Diet Icons
import Diabetes from 'assets/icons/diet/diabetes.svg';
import GlutenFreeDiet from 'assets/icons/diet/gluten-free.svg';
import Kosher from 'assets/icons/diet/kosher.svg';
import LactoseIntolerance from 'assets/icons/diet/lactose-intolerance.svg';
import LowCalories from 'assets/icons/diet/low-calories.svg';
import VeganDiet from 'assets/icons/diet/vegan.svg';
import VegetarianDiet from 'assets/icons/diet/vegetarian.svg';

// Product Category Icons
import Desserts from 'assets/icons/product-categories/desserts.svg';
import GrilledFish from 'assets/icons/product-categories/grilled-fish.svg';
import GrilledMeat from 'assets/icons/product-categories/grilled-meat.svg';
import Highlights from 'assets/icons/product-categories/highlights.svg';
import Starters from 'assets/icons/product-categories/starters.svg';
import Vegetarian from 'assets/icons/product-categories/vegetarian.svg';

// Icons
import Address from 'assets/icons/address.svg';
import AlertCircle from 'assets/icons/alert-circle.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import Attach from 'assets/icons/attach.svg';
import Calendar from 'assets/icons/calendar.svg';
import Check from 'assets/icons/check.svg';
import CheckCircle from 'assets/icons/check-circle.svg';
import CheckCircleGreen from 'assets/icons/check-circle--green.svg';
import CheckCircleRed from 'assets/icons/check-circle--red.svg';
import ChevronDown from 'assets/icons/chevron-down.svg';
import ChevronLeft from 'assets/icons/chevron-left.svg';
import ChevronRight from 'assets/icons/chevron-right.svg';
import ChevronUp from 'assets/icons/chevron-up.svg';
import Clock from 'assets/icons/clock.svg';
import Close from 'assets/icons/close.svg';
import CloseCircle from 'assets/icons/close-circle.svg';
import Copy from 'assets/icons/copy.svg';
import Download from 'assets/icons/download-simple.svg';
import Edit from 'assets/icons/edit.svg';
import Eye from 'assets/icons/eye.svg';
import ExternalLink from 'assets/icons/external-link.svg';
import EyeClosed from 'assets/icons/eye-closed.svg';
import Facebook from 'assets/icons/facebook.svg';
import FolderOpen from 'assets/icons/folder-open.svg';
import FolderOpenWhite from 'assets/icons/folder-open-white.svg';
import FunnelSimple from 'assets/icons/funnel-simple.svg';
import Google from 'assets/icons/google.svg';
import HomeWhite from 'assets/icons/home-white.svg';
import ImageLoader from 'assets/icons/image-loader.svg';
import InfoCircle from 'assets/icons/info-circle.svg';
import Instagram from 'assets/icons/instagram.svg';
import Loader from 'assets/icons/loader.svg';
import Location from 'assets/icons/location.svg';
import Lock from 'assets/icons/lock.svg';
import Newspaper from 'assets/icons/newspaper.svg';
import NewspaperWhite from 'assets/icons/newspaper-white.svg';
import PauseCircle from 'assets/icons/pause-circle.svg';
import Pencil from 'assets/icons/pencil.svg';
import Phone from 'assets/icons/phone.svg';
import Play from 'assets/icons/play.svg';
import PlayCircle from 'assets/icons/play-circle.svg';
import PlayFilled from 'assets/icons/play-filled.svg';
import Plus from 'assets/icons/plus.svg';
import PlusCircle from 'assets/icons/plus-circle.svg';
import Products from 'assets/icons/products.svg';
import ProductsWhite from 'assets/icons/products-white.svg';
import QrCode from 'assets/icons/qr-code.svg';
import Search from 'assets/icons/search.svg';
import Settings from 'assets/icons/settings.svg';
import SignOut from 'assets/icons/sign-out.svg';
import SignOutWhite from 'assets/icons/sign-out-white.svg';
import SortIcon from 'assets/icons/sort-icon.svg';
import Star from 'assets/icons/star.svg';
import Trash from 'assets/icons/trash.svg';
import Twitter from 'assets/icons/twitter.svg';
import Upload from 'assets/icons/upload.svg';
import User from 'assets/icons/user.svg';
import UserCircle from 'assets/icons/user-circle.svg';
import UserCircle2 from 'assets/icons/user-circle-2.svg';
import VerticalDots from 'assets/icons/vertical-dots.svg';
import Warning from 'assets/icons/warning.svg';
import WarningCircle from 'assets/icons/warning-circle.svg';

// Locales
import en from 'assets/icons/locales/en.svg';
import pt from 'assets/icons/locales/pt.svg';


const iconDict = {
  address: Address,
  'alert-circle': AlertCircle,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  attach: Attach,
  calendar: Calendar,
  check: Check,
  'check-circle': CheckCircle,
  'check-circle--green': CheckCircleGreen,
  'check-circle--red': CheckCircleRed,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  clock: Clock,
  close: Close,
  'close-circle': CloseCircle,
  copy: Copy,
  desserts: Desserts,
  diabetes: Diabetes,
  download: Download,
  edit: Edit,
  en,
  'external-link': ExternalLink,
  eye: Eye,
  'eye-closed': EyeClosed,
  facebook: Facebook,
  'folder-open': FolderOpen,
  'folder-open-white': FolderOpenWhite,
  'funnel-simple': FunnelSimple,
  'gluten-free-diet': GlutenFreeDiet,
  google: Google,
  'grilled-fish': GrilledFish,
  'grilled-meat': GrilledMeat,
  highlights: Highlights,
  'home-white': HomeWhite,
  'image-loader': ImageLoader,
  'info-circle': InfoCircle,
  instagram: Instagram,
  kosher: Kosher,
  'lactose-intolerance': LactoseIntolerance,
  loader: Loader,
  location: Location,
  lock: Lock,
  'low-calories': LowCalories,
  newspaper: Newspaper,
  'newspaper-white': NewspaperWhite,
  'pause-circle': PauseCircle,
  pencil: Pencil,
  phone: Phone,
  play: Play,
  'play-circle': PlayCircle,
  'play-filled': PlayFilled,
  plus: Plus,
  'plus-circle': PlusCircle,
  products: Products,
  'products-white': ProductsWhite,
  pt,
  'qr-code': QrCode,
  search: Search,
  settings: Settings,
  'sign-out': SignOut,
  'sign-out-white': SignOutWhite,
  'sort-icon': SortIcon,
  star: Star,
  starters: Starters,
  trash: Trash,
  twitter: Twitter,
  upload: Upload,
  user: User,
  'user-circle': UserCircle,
  'user-circle-2': UserCircle2,
  'vegan-diet': VeganDiet,
  vegetarian: Vegetarian,
  'vegetarian-diet': VegetarianDiet,
  'vertical-dots': VerticalDots,
  warning: Warning,
  'warning-circle': WarningCircle
};

const Icon = ({ name, ...props }) => {
  const IconComponent = iconDict[name];

  if (!IconComponent) return null;

  return <IconComponent {...props} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(iconDict)).isRequired
};

export default Icon;
