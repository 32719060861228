import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

// Utils
import checkAuthenticationError from 'utils/check-authentication-error';
import formatHolidaysData from 'utils/format-holidays-data';
import formatObjectKeys from 'utils/format-snake-case-to-camel-case';
import formatScheduleData from 'utils/format-schedule-data';
import RestaurantsApi from 'utils/api/restaurants';

import { logoutUser } from './userSlice';


export const createRestaurant = createAsyncThunk(
  'products/createRestaurant',
  async ({ restaurantData }, { rejectWithValue }) => RestaurantsApi.create(restaurantData, { 'Content-Type': 'multipart/form-data' })
    .then((data) => {
      const formattedRestaurant = formatObjectKeys(data?.data);

      if (formattedRestaurant) {
        const {
          scheduleClosedEvents,
          scheduleDayBlocks,
          ...remainingData
        } = formattedRestaurant;

        // Format schedule
        const schedule = formatScheduleData(scheduleDayBlocks);

        // Format holidays
        const holidays = formatHolidaysData(scheduleClosedEvents);

        return {
          ...remainingData,
          holidays,
          schedule
        };
      }

      return formattedRestaurant;
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const fetchRestaurants = createAsyncThunk(
  'restaurants/fetchRestaurants',
  (_, { rejectWithValue }) => RestaurantsApi.list()
    .then((data) => {
      let formattedData = [];

      if (Array.isArray(data.data)) formattedData = data.data.map(restaurant => formatObjectKeys(restaurant));

      const restaurantsWithSchedules = formattedData.map((restaurant) => {
        const {
          scheduleClosedEvents,
          scheduleDayBlocks,
          ...remainingData
        } = restaurant;

        // Format schedule
        const schedule = formatScheduleData(scheduleDayBlocks);

        // Format holidays
        const holidays = formatHolidaysData(scheduleClosedEvents);

        return {
          ...remainingData,
          holidays,
          schedule
        };
      });

      return restaurantsWithSchedules;
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const updateRestaurant = createAsyncThunk(
  'products/updateRestaurant',
  async ({ restaurantId, updatedRestaurantData }, { rejectWithValue }) => RestaurantsApi.update(restaurantId, updatedRestaurantData, { 'Content-Type': 'multipart/form-data' })
    .then(({ data, imageUploadErrors }) => {
      const formattedRestaurant = formatObjectKeys(data);

      if (formattedRestaurant) {
        const {
          scheduleClosedEvents,
          scheduleDayBlocks,
          ...remainingData
        } = formattedRestaurant;

        // Format schedule
        const schedule = formatScheduleData(scheduleDayBlocks);

        // Format holidays
        const holidays = formatHolidaysData(scheduleClosedEvents);

        return {
          ...remainingData,
          holidays,
          imageUploadErrors,
          schedule
        };
      }

      return formattedRestaurant;
    })
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

export const validateRestaurantSlug = createAsyncThunk(
  'restaurants/validateRestaurantSlug',
  (data, { rejectWithValue }) => RestaurantsApi.validateSlug(data)
    .catch((error) => {
      checkAuthenticationError(error);

      return rejectWithValue(error);
    })
);

// Adapter
const restaurantsAdapter = createEntityAdapter();

// Additional Initial State Options
const additionalInitialStateOptions = {
  isFetchingRestaurants: false,
  isUpdatingRestaurant: false,
  isValidatingRestaurantSlug: false,
  shouldUpdateHolidaysData: true,
  shouldUpdateScheduleData: true
};

const restaurantsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Create Restaurants Handlers
      .addCase(createRestaurant.pending, (state) => {
        state.isUpdatingRestaurant = true;
      })
      .addCase(createRestaurant.fulfilled, (state, { payload }) => {
        state.isUpdatingRestaurant = false;

        const restaurant = payload;

        if (restaurant) {
          restaurantsAdapter.upsertOne(state, restaurant);
        }
      })
      .addCase(createRestaurant.rejected, (state) => {
        state.isUpdatingRestaurant = false;
      })
      // Fetch Restaurants Handlers
      // Currently our App only handle a single restaurant, but in our database a User may have multiple Restaurants;
      // That's why the fetch response contains an array of Restaurants;
      .addCase(fetchRestaurants.pending, (state) => {
        state.isFetchingRestaurants = true;
      })
      .addCase(fetchRestaurants.fulfilled, (state, { payload }) => {
        state.isFetchingRestaurants = false;

        const restaurants = payload;

        if (Array.isArray(restaurants)) {
          restaurantsAdapter.setAll(state, restaurants);
        }
      })
      .addCase(fetchRestaurants.rejected, (state) => {
        state.isFetchingRestaurants = false;
      })
      // Handle Logout
      .addCase(logoutUser, () => ({
        ...restaurantsAdapter.getInitialState()
      }))
      // Update Restaurants Handlers
      .addCase(updateRestaurant.pending, (state) => {
        state.isUpdatingRestaurant = true;
      })
      .addCase(updateRestaurant.fulfilled, (state, { payload }) => {
        state.isUpdatingRestaurant = false;
        state.shouldUpdateHolidaysData = true;
        state.shouldUpdateScheduleData = true;

        const restaurant = payload;

        if (restaurant) {
          restaurantsAdapter.upsertOne(state, restaurant);
        }
      })
      .addCase(updateRestaurant.rejected, (state) => {
        state.isUpdatingRestaurant = false;
      })
      // Validate Restaurant Slug Handlers
      .addCase(validateRestaurantSlug.pending, (state) => {
        state.isValidatingRestaurantSlug = true;
      })
      .addCase(validateRestaurantSlug.fulfilled, (state) => {
        state.isValidatingRestaurantSlug = false;
      })
      .addCase(validateRestaurantSlug.rejected, (state) => {
        state.isValidatingRestaurantSlug = false;
      });
  },

  initialState: restaurantsAdapter.getInitialState(additionalInitialStateOptions),

  name: 'restaurants',

  reducers: {
    confirmHolidaysUpdate: (state) => {
      state.shouldUpdateHolidaysData = false;
    },

    confirmScheduleUpdate: (state) => {
      state.shouldUpdateScheduleData = false;
    }
  }
});

export default restaurantsSlice.reducer;

// Actions
export const {
  confirmHolidaysUpdate,
  confirmScheduleUpdate
} = restaurantsSlice.actions;

// Selectors
const restaurantsSelectors = restaurantsAdapter.getSelectors(state => state.restaurants);

export const { selectAll: selectRestaurants } = restaurantsSelectors;

export const selectCurrentRestaurant = createSelector(
  selectRestaurants,
  restaurants => restaurants[0]
);

export const selectIsFetchingRestaurants = state => state.restaurants.isFetchingRestaurants;

export const selectIsUpdatingRestaurant = state => state.restaurants.isUpdatingRestaurant;

export const selectIsValidatingRestaurantSlug = state => state.restaurants.isValidatingRestaurantSlug;

export const selectShouldUpdateHolidaysData = state => state.restaurants.shouldUpdateHolidaysData;

export const selectShouldUpdateScheduleData = state => state.restaurants.shouldUpdateScheduleData;
