import useTranslation from 'next-translate/useTranslation';


const useTranslationWithFallback = (namespace) => {
  const { t, lang } = useTranslation(namespace);

  return {
    lang,
    t: (key, ...args) => {
      const currentText = `${namespace}:${key}`;
      const fallbackText = `${namespace}EN:${key}`;
      const currentTranslation = t(currentText, ...args);

      if (currentTranslation === currentText) return t(fallbackText, ...args);

      return currentTranslation;
    }
  };
};

export default useTranslationWithFallback;
