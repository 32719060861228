import store from 'store/store';
import { logoutUser } from 'store/slices/userSlice';


const checkAuthenticationError = (error) => {
  const { status } = error || {};

  if (status === 401) {
    store.dispatch(logoutUser({ displaySessionExpiredMessage: true }));
  }
};

export default checkAuthenticationError;
