import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as RadixToast from '@radix-ui/react-toast';

// Context
import { useToastContext } from 'context/toast-context';

// Utils
import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

// TODO: This component should only be imported in Layout and used with the context.
// TODO: Remove props when the component is no longer imported in components and get the data from the context.
const Toast = ({
  className,
  description,
  icon,
  isOpen,
  title
}) => {
  const {
    handleToastVisibilityChange
  } = useToastContext();

  const handleToastOpenChange = (value) => {
    handleToastVisibilityChange(value);
  };

  useEffect(() => {
    handleToastVisibilityChange(isOpen);
  }, [handleToastVisibilityChange, isOpen]);

  return (
    <RadixToast.Provider>
      <RadixToast.Root
        className={styles.toast}
        duration={3000}
        open={isOpen}
        onOpenChange={handleToastOpenChange}
      >
        <div className={styles.toast__content}>
          {!!icon && (
            <Icon
              className={styles.toast__icon}
              name={icon}
            />
          )}
          <div>
            {!!title && (
              <RadixToast.Title className={styles.toast__title}>
                {title}
              </RadixToast.Title>
            )}
            {!!description && (
              <RadixToast.Description className={`${title ? ` ${styles.toast__description}` : ''}`}>
                {description}
              </RadixToast.Description>
            )}
          </div>
        </div>
      </RadixToast.Root>
      <RadixToast.Viewport className={`${styles.toast__viewport}${className ? ` ${className}` : ''}`} />
    </RadixToast.Provider>
  );
};

Toast.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string
};

Toast.defaultProps = {
  className: null,
  description: null,
  icon: PropTypes.string,
  isOpen: false,
  title: null
};

export default Toast;
