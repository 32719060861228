const GET_USER = '/get-user';
const LOGIN = '/login';
const MENUS = '/menus';
const PRODUCTS = '/products';
const PUBLIC_RESTAURANT = '/public-restaurant';
const REGISTER = '/register';
const REQUEST_PASSWORD_RESET = '/password-reset';
const REQUEST_PASSWORD_RESET_RESEND = '/password-reset/resend';
const RESET_PASSWORD = '/password-reset/update';
const RESTAURANTS = '/restaurants';
const UPDATE_USER_GENERAL = '/users';
const UPDATE_USER_PASSWORD = '/users/password';
const VALIDATE_RESTAURANT_SLUG = '/validate-restaurant-slug';

export {
  GET_USER,
  LOGIN,
  MENUS,
  PRODUCTS,
  PUBLIC_RESTAURANT,
  REGISTER,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_RESEND,
  RESET_PASSWORD,
  RESTAURANTS,
  UPDATE_USER_GENERAL,
  UPDATE_USER_PASSWORD,
  VALIDATE_RESTAURANT_SLUG
};
