import axios from 'axios';
import BaseApi from 'utils/api/base-api';

import {
  PUBLIC_RESTAURANT,
  RESTAURANTS,
  VALIDATE_RESTAURANT_SLUG
} from 'utils/api/endpoints';

// TODO: export this constant from base-api file
export const BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8080';

export default class RestaurantsApi extends BaseApi {
  static create = (data, headers) => this.post(RESTAURANTS, data, headers);

  static list = () => this.get(RESTAURANTS);

  static getPublicMenu = restaurantSlug => axios.get(`${BASE_URL}${PUBLIC_RESTAURANT}/${restaurantSlug}`);

  static update = (restaurantId, data, headers) => this.patch(`${RESTAURANTS}/${restaurantId}`, data, headers)
    .catch(this.handleError);

  static validateSlug = data => this.post(`${VALIDATE_RESTAURANT_SLUG}`, data);
}
