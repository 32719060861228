module.exports = {
  defaultLocale: 'en-EN',
  loadLocaleFrom: (language, namespace) => import(`/src/data/locales/${language}/${namespace}.json`).then(m => m.default),
  locales: [
    'en-EN',
    'pt-PT'
  ],
  pages: {
    '*': ['common', '404'],
    '/': ['dashboard', 'sidebar'],
    '/account-settings': ['account-settings', 'sidebar'],
    '/blog': ['blog'],
    '/blog/[slug]': ['blog'],
    '/brand-materials': ['brand-materials', 'sidebar'],
    '/edit-menu': ['edit-menu', 'sidebar'],
    '/login': ['login'],
    '/products': ['products', 'sidebar'],
    '/register': ['register'],
    '/request-password-reset': ['request-password-reset'],
    '/reset-password': ['reset-password'],
    '/restaurant-menu': ['restaurant-menu'],
    '/restaurant-profile': ['restaurant-profile', 'sidebar'],
    '/styleguide': ['styleguide'],
    '/welcome': ['welcome', 'restaurant-profile']
  }
};
