const emptyNewHoliday = {
  active: false,
  dates: [
    {
      from: '',
      to: ''
    }
  ]
};

const emptyNewSchedule = [
  {
    active: false,
    day: 'Monday',
    timeEntries: []
  },
  {
    active: false,
    day: 'Tuesday',
    timeEntries: []
  },
  {
    active: false,
    day: 'Wednesday',
    timeEntries: []
  },
  {
    active: false,
    day: 'Thursday',
    timeEntries: []
  },
  {
    active: false,
    day: 'Friday',
    timeEntries: []
  },
  {
    active: false,
    day: 'Saturday',
    timeEntries: []
  },
  {
    active: false,
    day: 'Sunday',
    timeEntries: []
  }
];

const emptyNewTimeEntry = {
  closeTime: '',
  openTime: ''
};

const weekdaysByName = {
  friday: 4,
  monday: 0,
  saturday: 5,
  sunday: 6,
  thursday: 3,
  tuesday: 1,
  wednesday: 2
};

const weekdaysByNumber = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
  7: 'Holiday'
};

export {
  emptyNewHoliday,
  emptyNewSchedule,
  emptyNewTimeEntry,
  weekdaysByName,
  weekdaysByNumber
};
